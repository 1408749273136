import React from 'react';
import {ReactComponent as PlaceIcon} from '../assets/placeholder.svg';
import {ReactComponent as TelephoneIcon} from '../assets/telephone.svg';
import {ReactComponent as MailIcon} from '../assets/email.svg';
import {ReactComponent as Facebook} from '../assets/facebook.svg';

const contactData = [
  {
    icon: <PlaceIcon className="address__icon" />,
    label: 'Adres',
    text: '32-300 Olkusz, Wspólna 23'
  },
  {
    icon: <MailIcon className="address__icon" />,
    label: 'E-mail',
    text: 'almetolkusz@o2.pl'
  },
  {
    icon: <TelephoneIcon className="address__icon" />,
    label: 'Telefon Almet 2',
    text: '+48 501 963 884'
  }
];

export default contactData;