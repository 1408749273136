import React, { useMemo } from 'react';
import useFetch from '../hooks/useFetch';
import GalleryImages from './GalleryImages';

function mapItems(images) {
  if(!images) {
    return [];
  }
  let list = []
  images.forEach(image =>{
    list.push({
      title: image.alt_text,
      url: image.source_url
    })
  })

  return list;
}

const Gallery = ({ id }) => {
  let data;
  id === 'almet' ? data = 2 : data = 5;
  let url = `https://wp.almet.olkusz.pl/wp-json/wp/v2/media?categories=${data}&per_page=50`
  const imagesList = useFetch(url);

  const memorizedImages = useMemo(() => mapItems(imagesList), [imagesList]);

  if (memorizedImages.length === 0)
    return <div></div>

  return <GalleryImages images={memorizedImages} />
};

export default Gallery;