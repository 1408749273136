import React from 'react';
import {ReactComponent as PlaceIcon} from '../assets/placeholder.svg';
import {ReactComponent as TelephoneIcon} from '../assets/telephone.svg';
import {ReactComponent as MailIcon} from '../assets/email.svg';

const contactData = [
  {
    icon: <PlaceIcon className="address__icon" />,
    label: 'Adres',
    text: '32-300 Olkusz, Rabsztyn 5'
  },
  {
    icon: <MailIcon className="address__icon" />,
    label: 'E-mail',
    text: 'almet.olkusz@interia.pl'
  },
  {
    icon: <TelephoneIcon className="address__icon" />,
    label: 'Telefon Almet 1',
    text: '+48 504 238 559'
  }
];

export default contactData;