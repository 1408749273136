import React from 'react';
import contactData from '../data/contactData';
import contactData2 from '../data/contactData2';
import Text from '../components/Text';

const ContactData = ({ id }) => {
  let data;
  id === 'almet' ? data = contactData : data = contactData2;
  return (
    data.map((item, key) =>
      <div className="address" key={key}>
        <div className="address__wrapper">{item.icon}</div>
        <label className="address__label">{item.label}</label>
        <Text color="white" className="address__text">{item.text}</Text>
      </div>
    )
  )
}

export default ContactData;