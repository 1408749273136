import { useState, useEffect } from 'react';

function usePolicy(state) {
  const [pageData, setPageData] = useState();

  useEffect(() => {
    fetch(`https://wp.almet.olkusz.pl/wp-json/wp/v2/posts?slug=polityka-prywatnosci&categories=1`)
      .then(res => res.json())
      .then(data => 
        setPageData({
          title: data[0].title.rendered,
          description: data[0].content.rendered
        })
      );
      
  }, [state]);

  return pageData;
};

export default usePolicy;