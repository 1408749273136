import React, { useState } from 'react';
import Button from './Button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Form = ({ id }) => {
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [messageStatus, setMessageStatus] = useState('Wyślij wiadomość')
  let data;
  id === 'almet' ? data = 5 : data = 177;

  async function handleSubmit(event) {
    event.preventDefault()
    setMessageStatus('wysyłanie')
    const form = new FormData()
    console.log(name + email + message)

    form.append('yourName', name)
    form.append('yourEmail', email)
    form.append('yourMessage', message)
    form.append('acceptance-736', true)

    const request = new XMLHttpRequest()
    request.open("POST", `https://www.wp.almet.olkusz.pl/wp-json/contact-form-7/v1/contact-forms/${data}/feedback`)
    request.send(form)
    setMessageStatus('Wiadomość została wysłana. Dziękujemy!')
  }

  const groupClasses = classNames (
    'form__overflow',
    messageStatus == 'Wiadomość została wysłana. Dziękujemy!' && 'form__overflow--hidden'
  )

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className={groupClasses}>
      <FormGroup>
        <Label>Imię i nazwisko</Label>
        <Input 
          type="text" 
          placeholder="Jan Kowalski" 
          onChange={e => setName(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Adres Email</Label>
        <Input
          type="text"
          placeholder="jan.kowalski@gmail.com"
          onChange={e => setEmail(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Treść wiadomości</Label>
        <textarea
          rows="5"
          placeholder="O co chcesz zapytać?"
          onChange={e => setMessage(e.target.value)}
          required
        />
      </FormGroup>
      <div className="form__group form__group--row">
        <Input type="checkbox" required/>
        <Label>Wysyłając wiadomość akceptuję postanowienia <Link to={`/${id}/polityka-prywatnosci`}>polityki prywatności</Link></Label>
      </div>
      </div>
      
      <Button type="submit" theme="secondary" className="form__button">{messageStatus}</Button>
    </form>
  )
}

const FormGroup = ({children}) => <div className="form__group">{children}</div>
const Label = ({ children }) => <label className="form__label">{children}</label>
const Input = ({ children, ...rest }) => (
  <input  className="form__input" {...rest}>{children}</input>
)

export default Form;