import React from 'react';
import Section from '../components/Section';
import Container from '../components/Container';
import aboutImage from '../assets/about.jpg';
import aboutImage2 from '../assets/paint.jpg';
import Button from '../components/Button';
import test from '../data/data.json';
import test2 from '../data/data2.json';
import offerList from '../data/offerList.json';
import NumberedList from '../components/NumberedList';
import Gallery from '../components/Gallery';
import Text from '../components/Text';
import Title from '../components/Title';
import Form from '../components/Form';
import Map from '../components/Map';
import ContactData from '../components/ContactData';
import { useParams, Link } from 'react-router-dom';
import Overlay from './Overlay';
import {ReactComponent as Facebook} from '../assets/facebook.svg';

const Home = () =>  {
  let { id } = useParams();
  let data;
  let phone;
  let email;
  let facebook;
  let list;
  let image;
  id === 'almet' ? image = aboutImage : image = aboutImage2;
  id === 'almet' ? data = test : data = test2;  
  id === 'almet' ? phone = '+48 504 238 559' : phone = '+48 501 963 884';
  id === 'almet' ? email = 'almet.olkusz@interia.pl' : email = 'almetolkusz@o2.pl';
  id === 'almet' ? facebook = <a href="https://www.facebook.com/pages/category/Industrial-Company/ALMET-Zak%C5%82ad-Produkcyjno-Us%C5%82ugowy-532138373788309/" className="address__text">Almet</a> : facebook = <a href="https://www.facebook.com/pages/category/Industrial-Company/ALMET-2-lakierowanie-proszkowe-piaskowanie-246843099136524/" className="address__text">Almet 2</a>
  id === 'almet' ? list = offerList.list : list = offerList.list2
  return (
    <Overlay>
    <Section className={`landing landing--${id}`} id="home">
      <Container className="landing__container">
        <div className="hero">
          <Title size="h1" color="white" className="hero__title">
            {data.main.title}
          </Title>
          <Text color="white" className="hero__text">{data.main.text}</Text>
          <a className="button button--primary" href={'/' + id + '/#kontakt'}>Zapytaj o ofertę</a>
        </div>
        <div className="landing__contact">
          <Text color="white">{phone}</Text>
          <Text color="white">{email}</Text>
        </div>
      </Container>
    </Section>

    <Section id="o-nas" id="o-nas">
      <Container className="about__container">
        <div className="hero">
          <Title className="hero__title">{data.about.title}</Title>
          <Text className="hero__text">{data.about.text}</Text>
          <Text className="hero__text">{data.about.text2}</Text>
        </div>
        <img 
          src={image} 
          className="image" 
          alt="almet - onas" 
        />
      </Container>
    </Section>

    <Section className="bg--white" id="oferta">
      <Container>
        <div className="hero">
          <Title className="hero__title">{data.offer.title}</Title>
          <Text className="hero__text">{data.offer.text}</Text>
        </div>
        <ul className="list list--withNumbers">
          <NumberedList data={list} />
        </ul>
      </Container>
    </Section>

    <Section className="realisation" id="realizacje">
      <Container className="realisation__container">
        <div className="hero self-end">
          <Title className="hero__title">{data.gallery.title}</Title>
          <Text>{data.gallery.text}</Text>
        </div>
        <Gallery id={id} />
      </Container>
    </Section>

    <Section className="contact bg--blue" id={'kontakt'}>
      <Container className="contact__container">
        <div className="hero">
          <Title color="white" className="hero__title">
            {data.contact.title}
          </Title>
          <Text color="white">
            {data.contact.text}
          </Text>
        </div>
        <Form id={id} />
        <div className="contact__address">
          <ContactData id={id} />
          <div className="address">
            <div className="address__wrapper"><Facebook className="address__icon"/></div>
            <label className="address__label">Facebook</label>
            {facebook}
          </div>
        </div>
        <Map
          id={id}
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDhGJWT04MuMS7mnZZnPtKtsphv89ufCjk"
          loadingElement={<div className="map__loader" />}
          containerElement={<div className="map__container" />}
          mapElement={<div className="map__area" />}
        />
      </Container>
    </Section>
  </Overlay>
  )
}

export default Home;