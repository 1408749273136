import { useState, useEffect } from 'react';

function usePageData(test, productSlug) {
  const [pageData, setPageData] = useState();
  
  useEffect(() => {
    
    fetch(`https://wp.almet.olkusz.pl/wp-json/wp/v2/posts?slug=${productSlug}&categories=${test}`)
      .then(res => res.json())
      .then(data => 
        setPageData({
          title: data[0].title.rendered,
          description: data[0].content.rendered
        })
      );
      
  }, [productSlug, test]);
  console.log(test)
  return pageData;
};

export default usePageData;