function useNumberTransform(number) {
  let newNumber;

  if (number < 10) {
    newNumber = '0' + number
  } else {
    newNumber = number
  }

  return newNumber;
};

export default useNumberTransform;