import React, { useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

const NavLink = ({ links, url, name }) => {
  let { id } = useParams();
  return <li className="menu__item"><a href={'/' + id + url}>{name}</a></li>
}

const NavMenu = ({ links, classNames }) => {
  return (
    <ul className={classNames}>
      {links.map((link, key) => (
        <NavLink url={link.url} name={link.name} key={key} />
      ))}
    </ul>
  )
}

const Navigation = ({ position, links }) => {
  const listClassName = classNames (
    'menu',
    position && `${position}__menu container`
  );

  return <NavMenu links={links} classNames={listClassName} />;
}

export default Navigation;