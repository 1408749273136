import React from 'react';
import classNames from 'classnames';

const Text = ({ align, color, className, children }) => {
  const classes = classNames(
    'text',
    align && `text--${align}`,
    color && `text--${color}`,
    className
  )

  return <p className={classes}>{children}</p>
}

export default Text;