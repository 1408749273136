import React from 'react';
import Section from '../components/Section';
import Container from '../components/Container';
import {ReactComponent as Logo1} from '../assets/almet1.svg';
import {ReactComponent as Logo2} from '../assets/almet2.svg';
import { Link } from 'react-router-dom';
import Title from '../components/Title';
import Text from '../components/Text';
import Button from '../components/Button';

const Intro = () => (
  <Section className="intro">
    <Link to="/almet" className="intro__overlay">
      <div className="intro__column column--1">
        {/* <Logo1 className="intro__logo" /> */}
        <Title>Almet</Title>
        <Text>Ponad 50 lat doświadczenia w obróbce metali.</Text>
        <Button theme="primary" url="/almet" className="intro__button">Odwiedź stronę</Button>
      </div>
    </Link>
    <Link to="/almet2" className="intro__overlay">
      <div className="intro__column column--2">
        {/* <Logo2 className="intro__logo"/> */}
        <Title>Almet 2</Title>
        <Text>Piaskowanie i lakierowanie proszkowe.</Text>
        <Button theme="primary" url="/almet2" className="intro__button">Odwiedź stronę</Button>
      </div>
    </Link>
  </Section>
);

export default Intro;