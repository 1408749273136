import React, { useState, useEffect, useRef } from 'react';
import {ReactComponent as NextIcon} from '../assets/next.svg';
import {ReactComponent as CloseIcon} from '../assets/close-cross.svg';
import useNumberTransform from '../hooks/useNumberTransform';
import classNames from 'classnames';

const GalleryImages = ({ images }) => {
  let containerRef = useRef();
  let [currentPos, setCurrentPos] = useState(1);
  let galleryImage = [];
  galleryImage[currentPos] = useRef(null);
  let test = images;
  let imagesQuanity = test.length;
  const [selectedImage, setSelectedImage] = useState(0);
  const [opened, setOpened] = useState(false);

  // ClassNames for full screen gallery

  const fullscreenGallery = classNames (
    'gallery__fullscreen',
    opened && 'gallery__fullscreen--is-visible'
  );

  // Functions for changing images trought button
  
  function imageChange(direction) {
    switch (direction) {
      case 'next':
        if (currentPos < imagesQuanity) {
          setCurrentPos(currentPos + 1)
        } else {
          setCurrentPos(1)
        }
        break;
    
      case 'prev':
        if (currentPos > 1) {
          setCurrentPos(currentPos - 1)
        } else {
          setCurrentPos(imagesQuanity)
        }
        break;
    }
  }

  function selectImage(image) {
    setSelectedImage(image);
    setOpened(true);
  }

  function imageSwitch(direction) {
    switch (direction) {
      case 'next':
        if (selectedImage < imagesQuanity - 1) {
          setSelectedImage(selectedImage + 1)
        } else {
          setSelectedImage(0)
        }
        break;
    
      case 'prev':
        if (selectedImage > 0) {
          setSelectedImage(selectedImage - 1)
        } else {
          setSelectedImage(imagesQuanity - 1)
        }
        break;
    }
  }

  // Interval for images

  useEffect(() => {
    const interval = setInterval(() => {imageChange('next')}, 5000);
    return () => clearInterval(interval)
  })

  useEffect(() => {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let currentImage = galleryImage[currentPos].current;
    if(supportsNativeSmoothScroll)
      containerRef.current.scrollTo({
        left: currentImage.offsetLeft, 
        behavior: 'smooth'
      })
    else
      containerRef.current.scrollLeft = (currentImage.offsetWidth + 11.1) * (currentPos - 1)
  }, [currentPos])

  let galleryImages = test.map((image, key) => (
    <img 
      className={key + 1 === currentPos ? 'gallery__image image current' : 'gallery__image image'}
      src={image.url}
      key={key + 1}
      alt={image.title}
      data-item-id={key + 1}
      ref={galleryImage[key + 1]}
      onClick={() => selectImage(key)}
    />
  ))

  return (
    <>
    <div className="gallery__navigation">
        <div className="gallery__buttons">
          <div 
            className="gallery__button gallery__button--rotated" 
            onClick={() => imageChange('prev')}
          >
            <NextIcon />
          </div>
          <div 
            className="gallery__button" 
            onClick={() => imageChange('next')}
          >
            <NextIcon />
          </div>
        </div>
        <div className="gallery__numbers">{useNumberTransform(currentPos)}/{useNumberTransform(imagesQuanity)}</div>
      </div>
      <div className="gallery">
        <div className="gallery__container" ref={containerRef}>
          {galleryImages}
        </div>

        <div className={fullscreenGallery}>
          
          <div className="gallery__fulscreen-wrapper">
          <div className="gallery__fulscreen-buttons">
            <CloseIcon className="gallery__fullscreen-button" onClick={() => setOpened(false)} />
            <div className="gallery__buttons">
              <div 
                className="gallery__button gallery__button--rotated" 
                onClick={() => imageSwitch('prev')}
              >
                <NextIcon />
              </div>
              <div 
                className="gallery__button" 
                onClick={() => imageSwitch('next')}
              >
                <NextIcon />
              </div>
            </div>
          </div>
            <img 
              src={test[selectedImage].url} 
              className="gallery__full-image"
            />
          
          </div>
        </div>
      </div>
    </>
  )
}

export default GalleryImages;