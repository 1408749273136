import React from 'react';
import classNames from 'classnames';

const Section = ({ className, as, id, children }) => {
  const classes = classNames(
    'section',
    className
  );

  const Component = as ? as.toLowerCase() : 'section';

  return (
    <Component id={id} className={classes}>
      {children}
    </Component>
  );
}

export default Section;