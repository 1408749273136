import React from 'react';
import Section from '../components/Section';
import Container from '../components/Container';
import usePageData from '../hooks/usePageData';
import { useRouteMatch, useParams } from 'react-router-dom'
import {ReactComponent as Facebook} from '../assets/facebook.svg';
import Title from '../components/Title';
import Text from '../components/Text';
import Form from '../components/Form';
import Map from '../components/Map';
import ContactData from '../components/ContactData';
import data from '../data/data.json';
import Overlay from './Overlay';

const Offer = () => {
  const { pageSlug } = useParams()
  const { id } = useParams();
  let test;
  let facebook;
  id === 'almet' ? facebook = <a href="https://www.facebook.com/pages/category/Industrial-Company/ALMET-Zak%C5%82ad-Produkcyjno-Us%C5%82ugowy-532138373788309/" className="address__text">Almet</a> : facebook = <a href="https://www.facebook.com/pages/category/Industrial-Company/ALMET-2-lakierowanie-proszkowe-piaskowanie-246843099136524/" className="address__text">Almet 2</a>;
  id === 'almet' ? test = 3 : test = 4;
  const product = usePageData(test, pageSlug)
  if(product === undefined)
    return (
      <div>loading</div>
    )

  return (
    <Overlay>
      <Section className={`landing landing--half landing--${id}`} id="home">
        <Container className="landing__container">
          <div className="hero hero--offer">
            <Title size="h1" color="white" className="hero__title">
              {product.title}
            </Title>
          </div>
        </Container>
      </Section>
      <Section className="offer-article">
        <Container>
          <div 
            dangerouslySetInnerHTML={{__html: product.description}}
            className="offer-article__content"
          />
        </Container>
      </Section>

      <Section className="contact bg--blue" id={'kontakt'}>
      <Container className="contact__container">
        <div className="hero">
          <Title color="white" className="hero__title">
            {data.offer.title}
          </Title>
          <Text color="white">
            Zostaw nam wiadomość lub kontakt do siebie. Skontaktujemy się z Tobą najszybciej jak to możliwe.
          </Text>
        </div>
        <Form id={id}/>
        <div className="contact__address">
          <ContactData id={id}/>
          <div className="address">
            <div className="address__wrapper"><Facebook className="address__icon"/></div>
            <label className="address__label">Facebook</label>
            {facebook}
          </div>
        </div>
        <Map 
          id={id}
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDhGJWT04MuMS7mnZZnPtKtsphv89ufCjk"
          loadingElement={<div className="map__loader" />}
          containerElement={<div className="map__container" />}
          mapElement={<div className="map__area" />}
        />
      </Container>
    </Section>
    </Overlay>
  )
}

export default Offer;