import React from 'react';
import Section from './Section';
import Container from './Container';
import Text from './Text';
import { Link, useParams } from 'react-router-dom';

const Footer = () => {
  const { id } = useParams();
  return (
    <Section as="footer" className="footer">
      <Container className="footer__container">
        <Text className="text--small">©2020  |  <Link to={`/${id}/polityka-prywatnosci`} className="text--small">Polityka prywatności</Link></Text>
        <Text className="text--small ">Design B.Spyrka</Text>
      </Container>
    </Section>
  );
}

export default Footer;