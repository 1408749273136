import React from 'react';
import { Link, useParams } from 'react-router-dom';

function numberTransform(number) {
  let newNumber;

  if (number < 10) {
    newNumber = '0' + number + '.'
  } else {
    newNumber = number + '.'
  }

  return newNumber;
};

const NumberedList = ({ data }) => {
  let { id } = useParams();

  return (
    data.map((item, key) =>
    <li className="list__item" key="key">
      <span className="list__number text--blue">
        {numberTransform(key+1)}
      </span>
      <Link to={`/${id}/oferta/${item.link.replace(/\s+/g, '-')}`} className="text">{item.title}</Link>
    </li>  
  ))
}

export default NumberedList;