import React from 'react';
import classNames from 'classnames';

const Title = ({ className, size, color, children }) => {
  const classes = classNames(
    'title',
    `text--${color}`,
    className
  )

  const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  const safeHeading = size ? size.toLowerCase() : ''
  const Heading = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p'

  return <Heading className={classes}>{children}</Heading>
}

Title.defaultProps = {
  size: 'h2',
  color: 'black'
}

export default Title;