import React, { useState, useLayoutEffect, useMemo } from 'react';
import className from 'classnames';
import { ReactComponent as Logo } from '../assets/almet1.svg';
import { ReactComponent as Logo2 } from '../assets/almet2.svg';
import { ReactComponent as CloseButton } from '../assets/close-cross.svg';
import { ReactComponent as OpenMenu } from '../assets/open-menu.svg';
import Container from './Container';
import { useParams, Link } from 'react-router-dom';
import Navigation from './Navigation';

const links = [
  {name: 'o nas', url: '/#o-nas', sub: null},
  {name: 'oferta', url: '/#oferta', sub: null},
  {name: 'realizacje', url: '/#realizacje', sub: null},
  {name: 'kontakt', url: '/#kontakt', sub: null},
];

const Header = () => {
  let { id } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuScrolled, setMenuScrolled] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);

  let BurgerIcon = menuOpen ? CloseButton : OpenMenu;
  let scrollPosition = 0;
  
  const navClassNames = className (
    'header__nav',
    'nav',
    menuOpen && 'header__nav--is-opened',
  );

  const headerClassNames = className (
    'header',
    menuScrolled && 'header--is-scrolled',
    menuHidden && 'header--is-hidden'
  )

  if (menuOpen === false) {
    document.body.style.position = "relative"
  } else {
    document.body.style.position = "fixed"
  }

  useLayoutEffect(() => {
    function onScroll() {
      let currentPostion = window.pageYOffset;

      currentPostion > scrollPosition
        ? setMenuHidden(true) 
        : setMenuHidden(false); 

      currentPostion === 0
        ? setMenuScrolled(false)
        : setMenuScrolled(true);

      scrollPosition = currentPostion;
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    };
  }, [scrollPosition])

  const navigation = <Navigation position="header" links={links} />
  return (
    <header className={headerClassNames}>
      <Container className="header__container">
        <a href={`/${id}/#home`}>
          {id === 'almet' ? <Logo className="logo"/> : <Logo2 className="logo"/>}
        </a>
        <BurgerIcon 
          className="header__collapse" 
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <nav className={navClassNames} onClick={() => setMenuOpen(!menuOpen)}>{navigation}</nav>
      </Container>
    </header>
  );
}

export default Header;