import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Overlay = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
};

export default Overlay;