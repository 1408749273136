import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Button = ({ url, theme, className, children }) => {
  const classes = classNames(
    'button',
    `button--${theme}`,
    className
  )

  if (url)
    return (
      <Link to={url} className={classes}>{children}</Link>
    )

  return <button type="submit" className={classes}>{children}</button>
};

Button.defaultProps = {
  theme: 'primary'
}

export default Button;