import React from 'react';
import Section from '../components/Section';
import Container from '../components/Container';
import Button from '../components/Button';
import Title from '../components/Title';

const NoMatch = () => (
  <Section className="landing">
    <Container className="landing__container nomatch">
      <div className="hero">
        <Title size="h1" color="white" className="hero__title">
          404 - Strona prawdopodobnie nie istnieje.
        </Title>
        <Button url="/#home">Strona główna</Button>
      </div>
    </Container>
  </Section>
);

export default NoMatch;