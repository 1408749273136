import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import '../styles/reset.css';
import '../styles/main.sass';
import Home from '../views/Home.js';
import Header from './Header';
import NoMatch from '../views/NoMatch.js';
import CookieConsent from "react-cookie-consent";
import Footer from './Footer';
import Offer from '../views/Offer';
import Polityka from '../views/Polityka';
import Intro from '../views/Intro';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Intro} />
          <Route exact path="/:id" component={Home} />
          <Route path="/:id/oferta/:pageSlug" component={Offer} />
          <Route path="/:id/polityka-prywatnosci" component={Polityka} />
          <Route component={NoMatch} />
        </Switch>
        <CookieConsent
          buttonText="Akceptuję"
          buttonStyle={{ backgroundColor: 'white'}}
        >
         <p>Na naszej stronie używamy plików cookie. Więcej dowiesz się w 
         <Link to="/polityka-prywatnosci"> polityce prywatności</Link>.</p>
        </CookieConsent>
      </Router>
    </div>
  );
}

export default App;


{/* <Header />
        <main>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/polityka-prywatnosci" component={Polityka} />
            <Route path="/oferta/:pageSlug" component={Offer} />
            <Route component={NoMatch} />
          </Switch> 
        </main>
        <Footer />
        <CookieConsent
          buttonText="Akceptuję"
          buttonStyle={{ backgroundColor: 'white'}}
        >
         <p>Na naszej stronie używamy plików cookie. Więcej dowiesz się w 
         <Link to="/polityka-prywatnosci"> polityce prywatności</Link>.</p>
        </CookieConsent> */}
